import React from "react";
import styled from "styled-components";

const Title = styled("svg")`
  * {
    ${(p) => p.theme.themedColor("fill", "onBackground")}
  }
`;

export const HovaLabs: React.FC = () => (
  <Title
    width="1014"
    height="147"
    viewBox="0 0 1014 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M87.4 2.99998V63.2H17.2V2.99998H0.399994V143H17.2V79.2H87.4V143H104.2V2.99998H87.4Z" />
    <path d="M197.323 129.4C189.723 129.4 182.723 128 176.323 124.8C169.923 121.8 164.523 117.6 160.123 112.4C155.723 107.2 152.123 101.2 149.723 94.4C147.323 87.6 146.123 80.6 146.123 73C146.123 65.6 147.323 58.4 149.723 51.6C152.123 44.8 155.723 38.8 160.123 33.6C164.523 28.4 169.923 24.4 176.323 21.2C182.723 18.2 189.723 16.6 197.323 16.6C204.923 16.6 211.723 18.2 218.123 21.2C224.523 24.4 229.923 28.4 234.323 33.6C238.723 38.8 242.323 44.8 244.723 51.6C247.123 58.4 248.523 65.6 248.523 73C248.523 80.6 247.123 87.6 244.723 94.4C242.323 101.2 238.723 107.2 234.323 112.4C229.923 117.6 224.523 121.8 218.123 124.8C211.723 128 204.923 129.4 197.323 129.4ZM197.323 0.599991C187.123 0.599991 177.723 2.59999 169.323 6.19999C160.923 9.99999 153.523 15.2 147.523 21.6C141.323 28.2 136.523 35.8 133.123 44.6C129.723 53.4 128.123 63 128.123 73C128.123 83.2 129.723 92.6 133.123 101.4C136.523 110.2 141.323 118 147.523 124.4C153.523 131 160.923 136.2 169.323 139.8C177.723 143.6 187.123 145.4 197.323 145.4C207.323 145.4 216.723 143.6 225.123 139.8C233.523 136.2 240.923 131 247.123 124.4C253.123 118 257.923 110.2 261.323 101.4C264.723 92.6 266.523 83.2 266.523 73C266.523 63 264.723 53.4 261.323 44.6C257.923 35.8 253.123 28.2 247.123 21.6C240.923 15.2 233.523 9.99999 225.123 6.19999C216.723 2.59999 207.323 0.599991 197.323 0.599991Z" />
    <path d="M333.239 147L391.639 2.99998H374.239L333.639 105.6L293.239 2.99998H274.839L333.239 147Z" />
    <path d="M432.798 89.2L456.798 27L480.798 89.2H432.798ZM448.998 2.99998L393.998 143H411.998L426.598 105.2H486.998L501.598 143H519.198L464.598 2.99998H448.998Z" />
    <path d="M593.173 2.99998V143H672.773V127H609.973V2.99998H593.173Z" />
    <path d="M714.83 89.2L738.83 27L762.83 89.2H714.83ZM731.03 2.99998L676.03 143H694.03L708.63 105.2H769.03L783.63 143H801.23L746.63 2.99998H731.03Z" />
    <path d="M858.75 63.4H835.95V19H858.75C866.75 19 872.95 21 877.35 25C881.75 29 884.15 34.4 884.15 41.2C884.15 48 881.75 53.4 877.35 57.4C872.95 61.4 866.75 63.4 858.75 63.4ZM862.95 127H835.95V79H862.95C867.75 79 872.15 79.8 875.75 81C879.35 82.2 882.35 84 884.75 86.2C887.15 88.4 888.75 91 889.95 93.8C891.15 96.6 891.75 99.8 891.75 103C891.75 106.4 891.15 109.6 889.95 112.4C888.75 115.2 887.15 117.8 884.75 120C882.35 122.2 879.35 124 875.75 125.2C872.15 126.4 867.75 127 862.95 127ZM819.15 2.99998V143H862.95C869.75 143 876.15 142.2 881.75 140.2C887.35 138.4 892.15 135.8 896.15 132.2C900.15 128.8 903.15 124.6 905.35 119.6C907.35 114.6 908.55 109 908.55 102.6C908.55 95.8 907.35 90.4 905.15 86C902.75 81.6 900.35 78.2 897.55 75.6C894.75 73.2 891.95 71.4 889.55 70.4C887.15 69.4 885.55 68.8 885.15 68.6C885.55 68.6 886.55 68 888.15 67C889.75 66 891.55 64.4 893.55 62.2C895.55 60 897.15 57.2 898.75 53.6C900.15 50 900.95 45.6 900.95 40.4C900.95 34.8 899.95 29.8 897.95 25.2C895.95 20.8 892.95 16.8 889.15 13.4C885.35 10.2 880.75 7.59998 875.15 5.79998C869.55 3.99998 863.15 2.99998 855.95 2.99998H819.15Z" />
    <path d="M996.245 107.2C996.245 111 995.445 114.2 994.045 117C992.445 119.8 990.645 122 988.245 123.8C985.845 125.8 983.045 127.2 979.845 128C976.645 129 973.445 129.4 970.245 129.4C966.245 129.4 962.645 128.8 959.445 127.6C956.245 126.4 953.245 124.8 950.845 122.8C948.245 120.8 946.045 118.6 944.045 116C942.045 113.4 940.645 110.6 939.445 107.8L925.445 116.2C927.045 119.8 929.045 123.4 931.845 127C934.445 130.6 937.645 133.6 941.445 136.4C945.045 139.2 949.445 141.4 954.245 143C959.045 144.6 964.445 145.4 970.445 145.4C976.445 145.4 982.045 144.6 987.245 142.8C992.445 141 997.045 138.4 1001.05 135C1004.85 131.6 1007.85 127.4 1010.05 122.6C1012.25 117.8 1013.45 112.2 1013.45 106C1013.45 100.8 1012.65 96.4 1011.05 92.4C1009.45 88.6 1007.05 85.2 1004.05 82C1001.05 79 997.445 76.2 993.045 73.6C988.645 71 983.845 68.4 978.645 66C973.845 64 969.445 61.8 965.645 59.8C961.645 57.8 958.445 55.8 955.845 53.6C953.045 51.6 951.045 49.2 949.645 46.8C948.045 44.4 947.445 41.4 947.445 38C947.445 35.4 947.845 32.8 948.845 30.2C949.845 27.6 951.445 25.2 953.445 23.2C955.445 21.2 957.845 19.6 960.645 18.4C963.445 17.2 966.845 16.6 970.845 16.6C974.045 16.6 977.045 17.2 979.845 18C982.645 18.8 985.045 20 987.445 21.4C989.845 22.8 991.845 24.4 993.645 26C995.445 27.8 997.045 29.6 998.245 31.2L1010.85 21C1009.25 18.8 1007.05 16.4 1004.65 14C1002.05 11.6 999.045 9.39998 995.845 7.39999C992.445 5.39999 988.645 3.79999 984.445 2.59999C980.245 1.39999 975.645 0.599991 970.845 0.599991C964.045 0.599991 958.245 1.79999 953.245 3.99998C948.245 6.39999 944.045 9.19999 940.845 12.8C937.445 16.4 934.845 20.6 933.245 25.2C931.445 29.8 930.645 34.4 930.645 39C930.645 44.6 931.645 49.6 934.045 53.8C936.245 58 939.245 61.6 942.845 64.8C946.445 68 950.645 70.8 955.245 73.2C959.845 75.6 964.445 78 969.045 80L979.845 85.4C983.045 87 986.045 89 988.445 91C990.845 93 992.645 95.4 994.045 98C995.445 100.6 996.245 103.6 996.245 107.2Z" />
  </Title>
);
